import React, { useCallback, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  Input,
  CardSubtitle,
  CardBody,
  Collapse,
} from 'reactstrap';
import { graphql } from 'gatsby';

import { Layout } from '../Components';

const ItemCard = ({ node }) => {
  const { acf, title } = node;

  const [collapse, setCollapse] = useState(false);
  return (
    <Card
      className={collapse ? 'my-2 PartnerCard PartnerCard__active' : 'my-2 PartnerCard'}
      onClick={() => setCollapse(!collapse)}
    >
      <CardBody>
        <CardTitle dangerouslySetInnerHTML={{ __html: title }} />
        <Button color="transparent" type="secondary" onClick={() => setCollapse(!collapse)} />
        {acf !== null && (
          <>
            <CardSubtitle>
              <span>Appel à projets:</span>
              {acf.appel}
            </CardSubtitle>
            <CardSubtitle>
              <span>Pays:</span>
              {acf.pays}
            </CardSubtitle>
            <CardSubtitle>
              <span> Secteur d&apos;activité:</span>
              {acf.activity_type}
            </CardSubtitle>
            <Collapse isOpen={collapse}>
              {acf.program && (
                <CardSubtitle>
                  <span>Sous-programme:</span>
                  {acf.program}
                </CardSubtitle>
              )}
              {acf.type && (
                <CardSubtitle>
                  <span>Type d’organisation :</span>
                  {acf.type}
                </CardSubtitle>
              )}
              {acf.creation_date && (
                <CardSubtitle>
                  <span>Date de création:</span>
                  {acf.creation_date}
                </CardSubtitle>
              )}
              {acf.adresse && (
                <CardSubtitle>
                  <span>Adresse:</span>
                  {acf.adresse}
                </CardSubtitle>
              )}
              {acf.code_postal && (
                <CardSubtitle>
                  <span>Code Postal:</span>
                  {acf.code_postal}
                </CardSubtitle>
              )}
              {acf.ville && (
                <CardSubtitle>
                  <span>Ville:</span>
                  {acf.ville}
                </CardSubtitle>
              )}
              {acf.gouvernorat && (
                <CardSubtitle>
                  <span>Gouvernorat/Etat:</span>
                  {acf.gouvernorat}
                </CardSubtitle>
              )}
              {acf.contact && (
                <CardSubtitle>
                  <span>Nom de la personne de contact:</span>
                  {acf.contact}
                </CardSubtitle>
              )}
              {acf.role && (
                <CardSubtitle>
                  <span>Rôle dans l&apos;organisation:</span>
                  {acf.role}
                </CardSubtitle>
              )}
              {acf.contact_email && (
                <CardSubtitle>
                  <span>Email de contact:</span>
                  {acf.contact_email}
                </CardSubtitle>
              )}
              {acf.contact_phone && (
                <CardSubtitle>
                  <span>Téléphone de contact:</span>
                  {acf.contact_phone}
                </CardSubtitle>
              )}
              {acf.website && (
                <CardSubtitle>
                  <span>Site Web:</span>
                  <a href={acf.website} title={acf.website}>
                    {acf.website}
                  </a>
                </CardSubtitle>
              )}
              {acf.contact_facebook && (
                <CardSubtitle>
                  <span>Page Facebook:</span>
                  <a href={acf.contact_facebook} title={acf.contact_facebook}>
                    {acf.contact_facebook}
                  </a>
                </CardSubtitle>
              )}
              {acf.presentation && (
                <CardSubtitle>
                  <span>Présentation de l&apos;organisme:</span>
                  <br />
                  {acf.presentation}
                </CardSubtitle>
              )}
              {acf.type && (
                <CardSubtitle>
                  <span>Type d&apos;inscription:</span>
                  {acf.type}
                </CardSubtitle>
              )}
              {acf.project && (
                <CardSubtitle>
                  <span>Nom du projet:</span>
                  {acf.project}
                </CardSubtitle>
              )}
              {acf.description && (
                <CardSubtitle>
                  <span>Description du projet:</span>
                  <br />
                  {acf.description.split('\n').map((i) => (
                    <p key={i}>{i}</p>
                  ))}
                </CardSubtitle>
              )}
              {acf.profile_partner && (
                <CardSubtitle>
                  <span>Profil du partenaire recherché:</span>
                  <br />
                  {acf.profile_partner}
                </CardSubtitle>
              )}
              {acf.existing_partner && (
                <CardSubtitle>
                  <span>Partenaires existants:</span>
                  <br />
                  {acf.existing_partner.split('\n').map((i) => (
                    <p key={i}>{i}</p>
                  ))}
                </CardSubtitle>
              )}
              {acf.sector_partner && (
                <CardSubtitle>
                  <span>secteur d&apos;activité du partenaire recherché:</span>
                  {acf.sector_partner}
                </CardSubtitle>
              )}
              {acf.country_partner && (
                <CardSubtitle>
                  <span>Pays du partenaire:</span>
                  {acf.country_partner}
                </CardSubtitle>
              )}
              {acf.issues && (
                <CardSubtitle>
                  <span>
                    Quels sont les problématiques sur lesquelles votre organisation souhaitent
                    travailler en 2020-2021? :
                  </span>
                  <br />
                  {acf.issues.split('\n').map((i) => (
                    <p key={i}>{i}</p>
                  ))}
                </CardSubtitle>
              )}
              {acf.project_type && (
                <CardSubtitle>
                  <span>Quel type de projet souhaitez-vous intégrer comme partenaire?:</span>
                  <br />
                  {acf.project_type.split('\n').map((i) => (
                    <p key={i}>{i}</p>
                  ))}
                </CardSubtitle>
              )}
            </Collapse>
          </>
        )}
      </CardBody>
    </Card>
  );
};

const Home = ({
  data: {
    allWordpressWpProjets: { edges },
  },
}) => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [country, setCountry] = useState('');
  const [call, setCall] = useState('');
  const paginate = useCallback((articles = [], size = 0, number = 0) => {
    if (number === 0 && size === 0) {
      return articles;
    }
    return articles.slice(0, (number + 1) * size);
  });
  const partners = edges.filter((item) => {
    if (!search && !country && !type && !call) return true;
    const {
      node: { title, acf },
    } = item;

    return (
      (!search || title.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      && ((country === '' || acf.pays.toLowerCase() === country.toLowerCase())
        && (type === '' || acf.activity_type.toLowerCase() === type.toLowerCase())
        && (call === '' || call.toLowerCase() === acf.appel.toLowerCase()))
    );
  });
  const maxPage = Math.ceil(partners.length / 6) - 1;
  const posts = paginate(partners, 6, page);
  return (
    <Layout>
      <Container>
        <Row className="my-2">
          <Col md="3" className="my-2">
            <Input
              type="text"
              name="Search"
              placeholder="Rechercher"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Col>
          <Col md="3" className="my-2">
            <Input
              type="select"
              name="pays"
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            >
              <option value="" defaultValue>
                Tous les pays
              </option>
              <option value="REPUBLIC OF ALBANIA">REPUBLIC OF ALBANIA</option>
              <option value="ARMENIA">ARMENIA</option>
              <option value="AUSTRIA">AUSTRIA</option>
              <option value="BELGIUM">BELGIUM</option>
              <option value="BOSNIA AND HERZEGOVINA">BOSNIA AND HERZEGOVINA</option>
              <option value="BULGARIA">BULGARIA</option>
              <option value="CROATIA">CROATIA</option>
              <option value="REPUBLIC OF CYPRUS">REPUBLIC OF CYPRUS</option>
              <option value="CZECH REPUBLIC">CZECH REPUBLIC</option>
              <option value="DENMARK">DENMARK</option>
              <option value="ESTONIA">ESTONIA</option>
              <option value="FINLAND">FINLAND</option>
              <option value="France">France</option>
              <option value="NORTH MACEDONIA">NORTH MACEDONIA</option>
              <option value="GEORGIA">GEORGIA</option>
              <option value="GERMANY">GERMANY</option>
              <option value="GREECE">GREECE</option>
              <option value="HUNGARY">HUNGARY</option>
              <option value="ICELAND">ICELAND</option>
              <option value="IRELAND">IRELAND</option>
              <option value="ITALY">ITALY</option>
              <option value="KOSOVO">KOSOVO</option>
              <option value="LATVIA">LATVIA</option>
              <option value="LITHUANIA">LITHUANIA</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="MALTA">MALTA</option>
              <option value="MOLDOVA">MOLDOVA</option>
              <option value="MONTENEGRO">MONTENEGRO</option>
              <option value="THE NETHERLANDS">THE NETHERLANDS</option>
              <option value="NORWAY">NORWAY</option>
              <option value="POLAND">POLAND</option>
              <option value="Portugal">Portugal</option>
              <option value="ROMANIA">ROMANIA</option>
              <option value="REPUBLIC OF SERBIA">REPUBLIC OF SERBIA</option>
              <option value="SLOVAKIA">SLOVAKIA</option>
              <option value="SLOVENIA">SLOVENIA</option>
              <option value="SPAIN">SPAIN</option>
              <option value="SWEDEN">SWEDEN</option>
              <option value="TUNISIA">TUNISIA</option>
              <option value="UNITED KINGDOM">UNITED KINGDOM</option>
              <option value="UKRAINE">UKRAINE</option>
              <option value="Pas de préférence">Pas de préférence</option>
            </Input>
          </Col>
          <Col md="3" className="my-2">
            <Input
              type="select"
              name="pays"
              onChange={(e) => {
                setCall(e.target.value);
              }}
            >
              <option value="" defaultValue>
                Tous les appels à projets
              </option>
              <option value="Coopération Européenne">Coopération Européenne</option>
              <option value="Traduction littéraire">Traduction littéraire</option>
              <option value="Accès aux marchés">Accès aux marchés</option>
              <option value="Education au cinéma">Education au cinéma</option>
            </Input>
          </Col>
          <Col md="3" className="my-2">
            <Input
              type="select"
              name="type"
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value="" defaultValue>
                Tous les secteurs d&apos;activité
              </option>
              <option value="Théâtre">Théâtre </option>
              <option value="Musique">Musique </option>
              <option value="Danse">Danse</option>
              <option value="Opéra">Opéra</option>
              <option value="Arts du cirque">Arts du cirque</option>
              <option value="Arts de la rue">Arts de la rue</option>
              <option value="Art de la marionnettes">Art de la marionnettes</option>
              <option value="Culture tangible - sites historiques et bâtiments">
                Culture tangible - sites historiques et bâtiments
              </option>
              <option value="Culture tangible - Musées">Culture tangible - Musées</option>
              <option value="Culture tangible - bibliothèques et archives">
                Culture tangible - bibliothèques et archives
              </option>
              <option value="Culture intangible">Culture intangible</option>
              <option value="Peinture, dessin">Peinture, dessin</option>
              <option value="Arts graphiques">Arts graphiques</option>
              <option value="Photographie">Photographie</option>
              <option value="Sculpture">Sculpture</option>
              <option value="Arts numériques">Arts numériques</option>
              <option value="Films, vidéos">Films, vidéos</option>
              <option value="Arts décoratifs">Arts décoratifs</option>
              <option value="Design graphique">Design graphique</option>
              <option value="Design de mode">Design de mode</option>
              <option value="Artisanat d'arts">Artisanat d&apos;arts</option>
              <option value="Ecriture créative">Ecriture créative</option>
              <option value="Traduction">Traduction</option>
              <option value="Edition">Edition</option>
              <option value="Architecture">Architecture</option>
              <option value="Autres">Autres</option>
            </Input>
          </Col>
        </Row>
        <Row className="my-2">
          {posts.map(({ node }) => (
            <Col lg="6" key={node.id}>
              <ItemCard node={node} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            {!(maxPage <= page) && (
              <Button
                type="primary"
                size="lg"
                className="d-block mx-auto text-center my-2"
                disabled={maxPage <= page}
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                Voir plus de fiches
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default Home;
export const query = graphql`
  {
    allWordpressWpProjets {
      edges {
        node {
          title
          id
          acf {
            activity_type
            adresse
            appel
            code_postal
            contact
            contact_email
            contact_facebook
            contact_phone
            country_partner
            creation_date
            description
            existing_partner
            gouvernorat
            name
            pays
            issues
            profile_partner
            presentation
            program
            project_type
            project
            role
            sector_partner
            type
            ville
            website
          }
        }
      }
    }
  }
`;
